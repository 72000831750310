import React from 'react';
import { useState, useEffect, useRef } from 'react';
import next from "../../../Assets/next-svg.svg";
import previous from "../../../Assets/prev-svg.svg";
import downArrow from "../../../Assets/down-arrow.svg";
import readMoreArrow from "../../../Assets/read-more-double-arrow.svg";
import playIcon from "../../../Assets/play_circle.svg";
import pauseIcon from "../../../Assets/pause_circle.svg";
import muteIcon from "../../../Assets/volume_off.svg";
import unMuteIcon from "../../../Assets/volume_on.svg";
import shareIcon from "../../../Assets/share_icon.svg";
import backIcon from "../../../Assets/back-arrow.svg";
import { autoChangeShortVideoAfterComplete } from '../../../constants';

const VIDEO_DURATION = 30000;
const IMAGE_DURATION = 7000;

const ShortsInstance = ({ data, isOpen, setIsOpen, activeIndex, setActiveIndex }) => {
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const videoRefs = useRef([]);
    const [progress, setProgress] = useState([]);
    const mediaTimers = useRef([]);

    const resetProgress = () => {
        return data.map((item) => ({
            videoProgress: Array(item.media.length).fill(0),
            imageProgress: Array(item.media.length).fill(0),
        }));
    };

    const closeShorts = () => {
        setIsOpen(false);
        setProgress(resetProgress());
        setCurrentMediaIndex(0);
        setIsMuted(false);
        setIsPaused(false);
        mediaTimers.current.forEach((timer) => clearTimeout(timer));
        mediaTimers.current = [];
        document.body.style.overflow = 'auto';
    };

    const handleEscapeKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeShorts();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleEscapeKeyDown);
        return () => window.removeEventListener('keydown', handleEscapeKeyDown);
    }, [])

    useEffect(() => {
        if (data?.length) {
            setProgress(resetProgress());
        }
    }, [data]);

    useEffect(() => {
        if (!isOpen) {
            closeShorts();
        } else {
            document.body.style.overflow = 'hidden';
        }
        return () => (document.body.style.overflow = 'auto');
    }, [isOpen]);

    const handleNextStory = () => {
        if (data[activeIndex]?.media?.length > currentMediaIndex + 1) {
            setProgress((prev) => {
                const updated = [...prev];
                if (updated[activeIndex]) {
                    if (data[activeIndex].media[currentMediaIndex + 1]?.type === "image") {
                        updated[activeIndex].imageProgress[currentMediaIndex + 1] = 0;
                    } else if (data[activeIndex].media[currentMediaIndex + 1]?.type === "video") {
                        updated[activeIndex].videoProgress[currentMediaIndex + 1] = 0;
                    }
                }
                return updated;
            });
            setCurrentMediaIndex((prev) => prev + 1);
        } else if (activeIndex < data.length - 1) {
            setProgress((prev) => {
                const updated = [...prev];
                const nextIndex = activeIndex + 1;

                if (updated[nextIndex]) {
                    if (data[nextIndex]?.media[0]?.type === "image") {
                        updated[nextIndex].imageProgress[0] = 0;
                    } else if (data[nextIndex]?.media[0]?.type === "video") {
                        updated[nextIndex].videoProgress[0] = 0;
                    }
                }
                return updated;
            });
            setActiveIndex((prev) => prev + 1);
            setCurrentMediaIndex(0);
        } else {
            closeShorts();
        }
    };

    const handlePreviousStory = () => {
        if (currentMediaIndex > 0) {
            setProgress((prev) => {
                const updated = [...prev];
                if (updated[activeIndex] && updated[activeIndex].imageProgress) {
                    updated[activeIndex].imageProgress[currentMediaIndex - 1] = 0;
                }
                return updated;
            });
            setCurrentMediaIndex((prev) => prev - 1);
        } else if (activeIndex > 0) {
            setActiveIndex((prev) => prev - 1);
            setCurrentMediaIndex(data[activeIndex - 1]?.media?.length - 1 || 0);

            setProgress((prev) => {
                const updated = [...prev];
                const previousIndex = activeIndex - 1;
                const lastMediaIndex = data[previousIndex]?.media?.length - 1;

                if (lastMediaIndex !== undefined && updated[previousIndex]) {
                    if (data[previousIndex].media[lastMediaIndex].type === 'image') {
                        updated[previousIndex].imageProgress[lastMediaIndex] = 0;
                    }
                }
                return updated;
            });
        }
    };

    const handleProgress = (e) => {
        const currentTime = e.target.currentTime;
        const duration = Math.min(e.target.duration, VIDEO_DURATION / 1000);
        const progressPercentage = (currentTime / duration) * 100;

        setProgress((prev) => {
            const updated = [...prev];
            if (updated[activeIndex] && updated[activeIndex].videoProgress) {
                updated[activeIndex].videoProgress[currentMediaIndex] = progressPercentage;
            }
            return updated;
        });
    };

    const handleImageProgress = () => {
        const timerInterval = 54;
        const progressIncrement = (100 / (IMAGE_DURATION / timerInterval));

        setProgress((prev) => {
            const updated = [...prev];
            if (updated[activeIndex] && updated[activeIndex].imageProgress) {
                const currentProgress = updated[activeIndex].imageProgress[currentMediaIndex] || 0;

                const updatedProgress = Math.min(currentProgress + progressIncrement, 100);

                updated[activeIndex].imageProgress[currentMediaIndex] = updatedProgress;
            }
            return updated;
        });
    };

    useEffect(() => {
        mediaTimers.current.forEach((timer) => clearTimeout(timer));
        mediaTimers.current = [];

        const currentMedia = data[activeIndex]?.media[currentMediaIndex];

        if (currentMedia?.type === 'image') {
            if (!isPaused) {
                const progressTimer = setInterval(handleImageProgress, 100);
                mediaTimers.current.push(progressTimer);

                const endTimer = setTimeout(() => {
                    clearInterval(progressTimer);
                    autoChangeShortVideoAfterComplete && handleNextStory();
                }, IMAGE_DURATION);
                mediaTimers.current.push(endTimer);
            }
        } else if (currentMedia?.type === 'video') {
            if (!isPaused) {
                const video = videoRefs.current[activeIndex]?.[currentMediaIndex];
                const videoDuration = video?.duration || VIDEO_DURATION / 1000;

                if (video) {
                    video.onended = () => {
                        autoChangeShortVideoAfterComplete && handleNextStory();
                    };
                }

                const videoTimer = setTimeout(() => {
                    autoChangeShortVideoAfterComplete && handleNextStory();
                }, Math.min(videoDuration * 1000, VIDEO_DURATION));
                mediaTimers.current.push(videoTimer);
            }
        }

        return () => {
            mediaTimers.current.forEach((timer) => clearTimeout(timer));
        };
    }, [activeIndex, currentMediaIndex, isPaused, data]);

    useEffect(() => {
        videoRefs.current.forEach((videoArr, index) => {
            videoArr.forEach((video, mediaIndex) => {
                if (video) {
                    if (index === activeIndex && mediaIndex === currentMediaIndex) {
                        video.muted = isMuted;
                        if (!isPaused) {
                            video.play();
                        } else {
                            video.pause();
                        }
                    } else {
                        video.pause();
                        // video.currentTime = 0;
                    }
                }
            });
        });
    }, [activeIndex, currentMediaIndex, isMuted, isPaused]);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-[10001] w-full h-screen">
                    <div className="relative shorts-container h-full">

                        {/* largeDevice navigation buttons */}
                        <button
                            className="absolute top-1/2 left-[31%] z-10 hidden lg:block"
                            onClick={handlePreviousStory}
                        >
                            <img src={previous} alt="previous" width="65" />
                        </button>
                        <button
                            className="absolute top-1/2 right-[31%] z-10 hidden lg:block"
                            onClick={handleNextStory}
                        >
                            <img src={next} alt="next" width="65" />
                        </button>

                        {/* small Device navigation */}
                        <div className='flex lg:hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 h-1/2 w-1/3 z-10 opacity-0 active:opacity-100 transition-opacity duration-300' onClick={handlePreviousStory}>
                            <img src={previous} alt="previous-icon" width={70} />
                        </div>
                        <div className='flex lg:hidden justify-end items-center absolute top-1/2 -translate-y-1/2 right-0 h-1/2 w-1/3 z-10 opacity-0 active:opacity-100 transition-opacity duration-300' onClick={handleNextStory}>
                            <img src={next} alt="next-icon" width={70} />
                        </div>

                        <div className="flex items-center justify-center w-full h-full overflow-hidden">
                            {data.map((item, index) => {
                                const offset = index - activeIndex;
                                const isActive = activeIndex === index;
                                const isPrevious = index === activeIndex - 1;
                                const isNext = index === activeIndex + 1;

                                let transformValue = 0;

                                if (isActive) {
                                    transformValue = 0;
                                } else if (isPrevious || isNext) {
                                    transformValue = offset * 450;
                                } else {
                                    transformValue = offset > 0 || offset < 0 ? offset * 350 : offset * 200;
                                }

                                return (
                                    <div
                                        key={item.id}
                                        className={`absolute transition-opacity duration-300 ${isActive ? "opacity-100" : "opacity-40"}`}
                                        style={{
                                            transform: `translateX(${transformValue}px)`,
                                        }}
                                    >
                                        <div className="relative md:rounded-[28px] overflow-hidden">
                                            <div
                                                className={`shorts-box flex md:block relative ${isActive
                                                    ? "w-screen h-screen md:w-[488px] md:h-[800px] md:rounded-[28px]"
                                                    : "w-[191px] h-[335px] rounded-[19px]"
                                                    }`}
                                            >
                                                {item.media.map((media, mediaIndex) => {
                                                    const isMediaActive = isActive && currentMediaIndex === mediaIndex;
                                                    return (
                                                        <React.Fragment key={mediaIndex}>
                                                            {media.type === "video" ? (
                                                                <video
                                                                    key={`${mediaIndex}-${activeIndex}`}
                                                                    ref={(el) => {
                                                                        if (!videoRefs.current[index]) {
                                                                            videoRefs.current[index] = [];
                                                                        }
                                                                        videoRefs.current[index][mediaIndex] = el;
                                                                    }}
                                                                    className={`w-full ${isMediaActive ? "" : isPrevious || isNext ? "" : "hidden"}`}
                                                                    poster={media.imgSrc}
                                                                    autoPlay={isMediaActive && !isPaused}
                                                                    muted={isMuted && isMediaActive}
                                                                    onTimeUpdate={handleProgress}
                                                                >
                                                                    <source src={media.src} />
                                                                </video>
                                                            ) : (
                                                                <img
                                                                    key={`${mediaIndex}-${activeIndex}`}
                                                                    src={media.src}
                                                                    alt={media.title}
                                                                    className={`w-full h-full ${isMediaActive ? "" : isPrevious || isNext ? "" : "hidden"}`}
                                                                />
                                                            )}
                                                            <div className="shorts-cont absolute bottom-0 w-full z-10 p-5">
                                                                {media.title && isMediaActive && (
                                                                    <h2 className={`block font-semibold text-white ${isActive ? 'title-animation text-[32px] md:text-[50px] mb-3 md:mb-6' : 'text-[19px]'}`}>
                                                                        {media.title}
                                                                    </h2>
                                                                )}
                                                                {media.title && media.text && isMediaActive ?
                                                                    <span className={`shorts-text-line ${isActive ? 'block' : 'hidden'}`} ></span>
                                                                    : ''
                                                                }
                                                                {media.text && isMediaActive && (
                                                                    <p className={`text-white mt-2 text-[30px] md:text-[40px] font-normal leading-2`}>
                                                                        {media.text}
                                                                    </p>
                                                                )}
                                                                {(media.text || media.title) && isMediaActive && (
                                                                    <div className={`shorts-read-more-btn ${isActive ? 'block' : 'hidden'}`}>
                                                                        <img src={readMoreArrow} alt="read-more-arrow" className='mx-auto mb-2' />
                                                                        <button className={`flex items-center gap-2 bg-white text-[#3A32D1] text-[14px] font-bold shadow-sm rounded-lg h-9 px-2 mx-auto`}>
                                                                            <img src={media?.src} alt="shorts-thumb" className='h-[22px] w-[22px] rounded-[4px] object-cover object-top' />
                                                                            Read More
                                                                            <img src={downArrow} alt="down-arrow" />
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}

                                                {/* mediaButtons */}
                                                <div className={`shorts-media-buttons ${isActive ? 'block' : 'hidden'} absolute top-9 md:top-14 right-3 flex items-center gap-2 z-10 bg-[#00000033] backdrop-blur-lg p-1 rounded-xl`}>
                                                    <button onClick={toggleMute}>
                                                        {isMuted ? (
                                                            <img src={muteIcon} alt="unmute-icon" />
                                                        ) : (
                                                            <img src={unMuteIcon} alt="mute-icon" />
                                                        )}
                                                    </button>
                                                    <button onClick={togglePause}>
                                                        {isPaused ? (
                                                            <img src={playIcon} alt="play-icon" />
                                                        ) : (
                                                            <img src={pauseIcon} alt="pause-icon" />
                                                        )}
                                                    </button>
                                                    <button className="block md:hidden">
                                                        <img src={shareIcon} alt="share-icon" />
                                                    </button>
                                                </div>

                                                {/* backButton */}
                                                <div className='absolute top-10 left-5 block md:hidden z-10'>
                                                    <button onClick={closeShorts} className='cursor-pointer'>
                                                        <img src={backIcon} alt="back-icon" />
                                                    </button>
                                                </div>

                                                {/* ProgressBar */}
                                                <div className={`${isActive ? 'flex' : 'hidden'} absolute top-5 md:top-6 left-5 md:left-6 w-11/12 md:w-10/12 gap-2`}>
                                                    {item.media.map((media, index) => {
                                                        const videoProgress = progress[activeIndex]?.videoProgress?.[index] ?? 0;
                                                        const imageProgress = progress[activeIndex]?.imageProgress?.[index] ?? 0;

                                                        return (
                                                            <div key={index} className="h-1 w-full rounded-[30px] bg-[#747474] overflow-hidden">
                                                                <span
                                                                    className="block bg-white h-full"
                                                                    style={{
                                                                        width: media.type === 'video' ? `${videoProgress}%` : `${imageProgress}%`,
                                                                        transition: 'width 0.4s ease',
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {/* shareButton */}
                                                <div className={`${isActive ? 'hidden md:flex' : 'hidden'} items-center justify-center absolute top-2 right-2 z-10 h-10 w-10 rounded-full bg-[#00000033] backdrop-blur-lg`}>
                                                    <img src={shareIcon} alt="share-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ShortsInstance;
