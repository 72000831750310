const isNonProd = window.location.href.includes("localhost:") || window.location.href.includes("dev2.cricket");

export const getMatchOversText = (matchObj, teamId, wrapParenthese = true) => {
    let res = '';
    try {
        const format = matchObj?.format || "";
        if (format) {
            const lower = format.toLowerCase();

            if (
                lower.includes("test") ||
                lower.includes("fc") ||
                lower.includes("first class")
            ) {
                res = getMatchOverTextFoVariableOversMatch(matchObj, teamId);
            } else {
                res = getMathchOverTextForFixedOversMatch(matchObj, teamId);
            }
        } else {
            res = getMathchOverTextForFixedOversMatch(matchObj, teamId);
        }

        if (res && wrapParenthese) {
            res = `(${res})`;
        }
    } catch (error) {

    }

    // This is added just for testing purpose.
    if (isNonProd) {
        res = res + "?";
    }
    return res;
}



// T20, ODI, T10, T20I
export const getMathchOverTextForFixedOversMatch = (matchObj, teamId) => {
    let team = null;

    if (matchObj?.teama?.team_id === teamId) {
        team = matchObj?.teama;
    } else if (matchObj?.teamb?.team_id === teamId) {
        team = matchObj?.teamb;
    }

    if (!team || !(team?.overs)) {
        return "";
    }

    return typeof team?.overs === "string" ? team?.overs : "";
}

export const getMatchOverTextFoVariableOversMatch = (matchObj, teamId) => {

    const battingTeamId = matchObj?.batting_team_id;

    if (!battingTeamId) {
        return "";
    }

    const status = matchObj?.status || matchObj?.status_str;
    if (status.toLowerCase() !== "live") {
        return "";
    }

    let team = null;

    if (matchObj?.teama?.team_id === teamId) {
        team = matchObj?.teama;
    } else if (matchObj?.teamb?.team_id === teamId) {
        team = matchObj?.teamb;
    }

    if (!team || !(team?.overs) || team?.team_id !== battingTeamId) {
        return "";
    }

    let oversText = team?.overs + "";

    if (oversText.includes("&")) {
        const parts = oversText.split("&");
        oversText = parts[parts.length - 1];
        oversText = oversText.trim();
    }
    return oversText;
}