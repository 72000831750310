import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAxiosSWR } from '../../../../axiosInstance';
import SEOMeta from '../Home/common/SEOMeta';

export default function CustomPage({ customSlug }) {
    const { slug } = useParams();
    const [num, setNum] = useState(0);
    const url = `/v1/misc/customPage/${customSlug || slug}?cg_random=${num}`;
    const { data, isLoading, error } = useAxiosSWR(url);
    const metaTitle = useMemo(() => {
        return ((slug || customSlug) + "").split("-").join(" ").replace(/\b\w/g, (c) => c.toUpperCase()) + ' | CricketGully'
    }, [slug, customSlug]);


    if (isLoading) {
        return <div className='container mx-auto px-[6px] md:px-4 py-8 break-words'><h2>Loading...</h2></div>;
    }

    if (error) {
        return (
            <div className='container mx-auto px-[6px] md:px-4 py-8 break-words'>
                <SEOMeta data={{ title: metaTitle }} />
                <h2>Error fetching content.</h2>
                <button className='bg-white rounded-md p-1 text-[#3A32D1] font-semibold px-3 hover:text-gray-300 hover:bg-[#3A32D1] transition ease-linear mt-3' onClick={() => {
                    setNum(prev => prev + 1);
                }}>Try Again</button>
            </div>
        )
    }

    if (!data || !(data?.id)) {
        return null;
    }

    return (
        <>
            <div className='container mx-auto px-[6px] md:px-4 py-8 break-words'>
                <SEOMeta data={{ title: metaTitle }} />
                {!customSlug && <h1 className="font-bold mb-8">{data.title}</h1>}
                <div className="px-4 py-3 md:p-10 bg-white rounded-xl">
                    <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
                </div>
            </div>
        </>
    )
}