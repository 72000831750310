import React, { useEffect, useState } from "react";
import Live from "./ScheduleInstance/Live";
import Upcoming from "./ScheduleInstance/Upcoming";
import Results from "./ScheduleInstance/Results";
import { Link, useParams } from "react-router-dom";
import { getMobileDetect, SlotProps } from "../../../constants";
import GoogleAdUnit from "../../common/GoogleAdUnit";

const MATCH_TYPES = {
  LIVE: "live",
  UPCOMING: "upcoming",
  COMPLETED: "results",
};
const MatchTypePage = [
  "Live",
  "Upcoming",
  "Results",
];
const Schedule = () => {
  const { type } = useParams()
  const [matchType, setMatchType] = useState("");
  useEffect(() => {
    if (type && type.toLowerCase) {
      setMatchType(type.toLowerCase())
    } else {
      setMatchType(MATCH_TYPES.LIVE)
    }
  }, [type])

  const renderComponent = () => {
    switch (matchType) {
      case MATCH_TYPES.LIVE:
        return <Live />;
      case MATCH_TYPES.UPCOMING:
        return <Upcoming />;
      case MATCH_TYPES.COMPLETED:
        return <Results />;
      default:
        return null; // Render nothing if type doesn't match
    }
  };
  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile());

  return (
    <div>
      <div className="md:mx-0 md:my-2 md:mt-4">
        <div className=" md:bg-white bg-[#312AB7] md:rounded-[20px] md:border text-sm cursor-pointer overflow-auto grid grid-cols-3 gap-[10px] md:p-2.5">
          {MatchTypePage.map((cat, i) => {
            return (
              <Link to={`/schedule/${cat.toLowerCase()}`} key={i} >
                <button
                  className={`text-[16px] md:text-[#6C6C6C] py-3 px-2 flex justify-center w-full text-white md:rounded-xl ${matchType === "live" ? "mb-1 md:mb-0" : ""} ${matchType === cat.toLowerCase()
                    ? `text-white md:text-white md:bg-[#3A32D1] border-b-[3px] md:border-b-0`
                    : ""
                    }`}
                >
                  {cat}
                </button>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="">
        <div className="md:my-4">
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
