import React, { useState } from 'react';
import ContactUs from '../common/ContactUs';
import contactusBanar from "../../Assets/contactusdectop.jpg";
import contactusBanarMobile from "../../Assets/contactus.jpg";
import usePost from '../../hooks/usePost';
import Banner from './common/Banner';
import SEOMeta from './Home/common/SEOMeta';

const ContactUsPages = () => {
    const reqObjContactus = usePost();
    const { isLoading, error } = reqObjContactus;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        body: '',
        type: "feedback"
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({}); // State to track validation errors

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: '' // Clear errors when user starts typing
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Full Name is required';
        if (!formData.email) newErrors.email = 'Email ID is required';
        if (!formData.subject) newErrors.subject = 'Subject is required';
        if (!formData.body) newErrors.body = 'Message is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        const response = await reqObjContactus.makeRequest("v1/misc/feedback", formData);
        if (response?.status === "success") {
            setFormData({
                name: '',
                email: '',
                subject: '',
                body: ''
            });
            setSuccessMessage("Thanks for being awesome! First of all, thank you for contacting us");
        }
    };

    return (
        <div>
            <SEOMeta data={{ title: "Contact Us | CricketGully" }} />
            <Banner
                title='Get in touch with us.'
                content={`We're here to assist you.`}
                largeBanner={contactusBanar}
                smallBanner={contactusBanarMobile}
            />
            <div>
                {successMessage && (
                    <div className='bg-green-100 border border-green-400 text-green-700 md:mx-[80px] lg:mx-[140px] xl:mx-[180px] py-3 px-4 mt-4 rounded'>
                        {"Thanks for being awesome! First of all, thank you for contacting us"}
                    </div>
                )}
                {error && (
                    <div className='bg-red-100 border border-red-400 text-red-700 md:mx-[80px] lg:mx-[140px] xl:mx-[180px] py-3 px-4 mt-4 rounded'>
                        Please try again after some time.
                    </div>
                )}
                <form
                    className={`bg-white border md:grid grid-cols-3 md:gap-9 rounded-3xl mx-[6px] md:mx-[80px] lg:mx-[140px] xl:mx-[180px] mt-4 mb-[40px] md:rounded-[20px] md:py-[54px] py-6 md:px-[54px] px-[20px] ${successMessage || error ? "" : "md:my-[80px] "}`}
                    onSubmit={handleSubmit}
                >
                    <div>
                        <input
                            type="text"
                            className={`border rounded-xl p-4 md:p-5 block w-full ${errors.name ? 'border-red-500' : ''}`}
                            placeholder='Full Name*'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-2">{errors.name}</p>}
                    </div>
                    <div>
                        <input
                            type="email"
                            className={`border rounded-xl p-4 md:p-5 block w-full md:mt-0 mt-4 ${errors.email ? 'border-red-500' : ''}`}
                            placeholder='Email ID*'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-2">{errors.email}</p>}
                    </div>
                    <div>
                        <input
                            type="text"
                            className={`border rounded-xl p-4 md:p-5 block w-full mt-4 md:mt-0 ${errors.subject ? 'border-red-500' : ''}`}
                            placeholder='Subject of contact*'
                            name='subject'
                            value={formData.subject}
                            onChange={handleChange}
                        />
                        {errors.subject && <p className="text-red-500 text-sm mt-2">{errors.subject}</p>}
                    </div>
                    <div className='col-start-1 col-end-4'>
                        <textarea
                            className={`border rounded-xl p-4 md:p-5 block mt-4 md:mt-0 w-full ${errors.body ? 'border-red-500' : ''}`}
                            placeholder='Message*'
                            name='body'
                            value={formData.body}
                            onChange={handleChange}
                        ></textarea>
                        {errors.body && <p className="text-red-500 text-sm mt-2">{errors.body}</p>}
                    </div>
                    <button
                        type="submit"
                        className='border rounded-xl p-4 md:p-5 bg-[#3A32D1] text-white mt-4 block w-full'
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending...' : 'Send'}
                    </button>
                </form>
                <ContactUs />
            </div>
        </div>
    );
};

export default ContactUsPages;
