import React, { useEffect } from 'react';
import { doHardReloadArticle } from '../../constants';

const OutbrainWidget = () => {
    useEffect(() => {
        const newDate = new Date();
        const time = newDate.getTime();
        const scriptTag = document.querySelector("script#outbrain-widget-script");
        // Check if the script is already added
        if (!scriptTag) {
            const script = document.createElement('script');
            script.src = `//widgets.outbrain.com/outbrain.js`;
            if (doHardReloadArticle === false) {
                script.src = script.src + "?time=" + time;
            }
            script.async = true;
            script.id = 'outbrain-widget-script';
            document.body.appendChild(script);
        }
        return () => {
            const scriptTag = document.querySelector("script#outbrain-widget-script");
            if (scriptTag) {
                scriptTag.remove(); // Removes the script tag from the DOM
            }
        }
    }, []); // Empty dependency array to run only on mount

    return (
        <div
            className="OUTBRAIN"
            data-widget-id="GS_1"
            dangerouslySetInnerHTML={{ __html: '' }} // Adds the div needed for Outbrain
        ></div>
    );
};

const MgAdWidget = () => {
    useEffect(() => {
        (function (w, q) { w[q] = w[q] || []; w[q].push(["_mgc.load"]) })(window, "_mgq");
    }, []); // Empty dependency array to run only on mount

    return (
        <div className='rounded-2xl bg-white p-4' data-type="_mgwidget" data-widget-id="1702878"></div>
    );
};

export default OutbrainWidget;