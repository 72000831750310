import React, { useState, useRef, useEffect, useContext } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import debounce from "lodash.debounce";
import { getScheduleMockData, processOnData } from "./helper";
import SEOMeta from "../../Home/common/SEOMeta";
import { FilterButtons } from "./common";
import { NoMatch } from "./components/NoMatch";
import { RecentMatchformatDates } from "../../../../helpers/dates";
import { MatchBox } from "../../../common/MatchBoxes/MatchBox";
import { doScrollToTop } from "../../../../helpers/general";
import AppContext, { URL_TYPES } from "src/context";
import { useLiveFilterButtonTopPositionOnScroll } from "../../../../hooks/useShowHideOnScroll";

const initialState = {
  gameFormats: [],
  datesList: [],
  matchesById: {},
  matchListByDates: {},
};
const mockLiveData = getScheduleMockData();
const Live = () => {
  const [isSelect, setIsSelect] = useState("All");
  const [page, setPage] = useState(0);
  const [state, setState] = useState(initialState);
  const { gameFormats, datesList, matchesById, matchListByDates } = state;
  const {
    data: liveData,
    error: isError,
    isLoading,
    mutate,
  } = useAxiosSWR(`/v1/matches/schedule/live?page=${page}`, {
    refreshInterval: 5000,
  });
  const liveMatchData = isLoading ? mockLiveData : liveData;
  const containerRef = useRef(null);
  const appContext = useContext(AppContext);
  useEffect(() => {
      const urlInfoObj = {
          type: URL_TYPES.SCHEDULE_LIVE,
          path: "/schedule/live",
          reqState: null,
      };
      appContext.updateUrlInfo(urlInfoObj);
  }, []);

  const handleMatchTypeChange = (type) => {
    if (!isLoading) {
      setIsSelect(type);
      doScrollToTop();
    }
  };

  useEffect(() => {
    if (liveMatchData) {
      setState(processOnData({ data: liveMatchData, schduleType: "live" }));
    }
  }, [liveMatchData]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const container = containerRef.current;
      if (
        container &&
        container.getBoundingClientRect().bottom <= window.innerHeight &&
        liveMatchData?.hasNextPage
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    }, 250);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [liveMatchData]);

  // Filter matches based on the selected game format
  const filteredMatches =
    isSelect === "All"
      ? matchListByDates
      : Object.fromEntries(
        Object.entries(matchListByDates).map(([date, matches]) => [
          date,
          matches.filter(
            (matchId) => matchesById[matchId].format === isSelect
          ),
        ])
      );
  const classname = liveMatchData?.isShimmer ? "bg-gray-300 loading text-gray-300" : "";
  const sheduleFilterButtonRef = useRef(null);
  useLiveFilterButtonTopPositionOnScroll(sheduleFilterButtonRef);
  return (
    <div className="md:mx-0 mx-[6px] md:bg-white md:rounded-xl md:border md:p-4 mt-2 md:mt-0">
      <SEOMeta slug="schedule-live" />
      <h1 className="hidden">
        Schedule Live
      </h1>
      <div ref={containerRef}>
        {gameFormats.length > 0 && (
          <div ref={sheduleFilterButtonRef} className="sticky top-[48px] md:static md:bg-transparent bg-[#eef0f3] z-30">
            <FilterButtons
              gameFormats={gameFormats}
              isSelect={isSelect}
              onMatchTypeChange={handleMatchTypeChange}
            />
          </div>
        )}
        {datesList.length > 0 ? (
          datesList.map((date, i) => (
            <div key={i} className="mb-2">
              <div className="px-2 py-4 pt-5">
                <div className={`${classname} md:min-h-[18px] md:min-w-[180px] min-w-[50px] md:text-[24px] leading-[28px] text-[#000000] font-bold flex items-center`}>
                  {RecentMatchformatDates(date)}
                  <span className="custom-border-right ml-6"></span>
                </div>
              </div>
              <div className={`col-span-4 md:border border-[#E3E3E4] rounded-xl ${filteredMatches[date]?.length > 0 ? '' : 'hideen'}`}>
                <SEOMeta slug="schedule-results" />
                {filteredMatches[date].length > 0 ? (
                  filteredMatches[date].map((match_id, j) => (
                    <div key={match_id} className={`${filteredMatches[date].length - 1 !== j ? 'border-b' : ''} bg-white md:bg-transparent ${j === 0 ? "" : "md:mt-0 mt-3"} md:rounded-none rounded-xl`}>
                      <MatchBox match={matchesById[match_id]} customClass={classname} />
                    </div>
                  ))
                ) : (
                  <div key={i} className="text-center flex justify-center border rounded-xl bg-white">
                    <NoMatch text="No Matches played on this date" gameAndDate={`${date}__${isSelect}__Live`}  />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="bg-white">
            <NoMatch text="No Live Matches available" gameAndDate={`No__Dates__Live`} />
          </div>
        )}
        {isError && (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Live;