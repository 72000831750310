import React from "react";
import logo from "../../Assets/logo.png";
import footerImage from "../../Assets/bgImage.png";
import footerMobileImage from "../../Assets/mobileBgImage.png";
import { Link } from "react-router-dom";
import { doScrollToTop } from "../../helpers/general";
import instagram from '../../Assets/instagram.svg';
import facebook from '../../Assets/facebook.svg';
import youtube from '../../Assets/youtube.svg';
import twitter from '../../Assets/x(twitter).svg';
import AppStore from '../../Assets/downkiadInTheAppStore.svg';
import google from '../../Assets/getItOnGooglePlay.svg';

const Footer = () => {
  return (
    <div
      className="bg-gradient relative"
      style={{
        backgroundImage: `linear-gradient(rgb(0, 0, 0), rgb(2, 0, 41), rgb(23 19 96))`,
      }}
    >
      {/* Add pointer-events: none to the background images */}
      <img
        src={footerImage}
        alt=""
        className="absolute top-0 border-b h-[268px] border-dashed border-[#FFFFFF33] hidden md:block pointer-events-none"
      />
      <img
        src={footerMobileImage}
        alt=""
        className="absolute bottom-[70px] w-full border-b border-dashed border-[#FFFFFF33] md:hidden pointer-events-none"
      />
      <div className="relative z-10 mx-auto pt-6 pb-8 px-4 lg:max-w-6xl lg:px-8">
        <div className="mt-12 mb-4 gap-y-10 md:flex md:flex-wrap justify-between xl:gap-x-8 ">
          <div className="w-max mx-auto md:mx-0">
            <img src={logo} alt="logo" className="w-[162px] mx-auto md:mx-0" />
            {/* <div className="flex justify-center gap-4 mt-6 md:mt-8">
              <div>
                <img src={google} alt="logo" className="cursor-pointer" />
              </div>
              <div>
                <img src={AppStore} alt="logo" className="cursor-pointer" />
              </div>
            </div> */}
          </div>
          <div>
            <div className="mt-10 md:mt-4 text-center">
              <ul className="md:flex gap-4">
                <div className="mb-4">
                  <Link
                    className="text-white mb-6 "
                    to="/"
                    onClick={doScrollToTop}
                  >
                    Home
                  </Link>
                </div>
                <div className="mb-4">
                  <Link
                    className="text-white mb-6"
                    to="/schedule/live"
                    onClick={doScrollToTop}
                  >
                    Schedule
                  </Link>
                </div>
                <div className="mb-4">
                  <Link
                    className="text-white mb-6"
                    to="/cricket-news"
                    onClick={doScrollToTop}
                  >
                    News
                  </Link>
                </div>
                <div className="mb-4">
                  <Link
                    className="text-white mb-6"
                    to="/teams"
                    onClick={doScrollToTop}
                  >
                    Teams
                  </Link>
                </div>
                <div className="mb-4">
                  <Link
                    to="/fantasy"
                    className="text-white mb-6"
                    onClick={doScrollToTop}
                  >
                    Fantasy
                  </Link>
                </div>
                <div className="mb-4">
                  <Link
                    to="/series"
                    className="text-white mb-6"
                    onClick={doScrollToTop}
                  >
                    Series
                  </Link>
                </div>
              </ul>
            </div>
            <div className="hidden md:block ml-full mt-8">
              <ul className="list-none flex gap-4 justify-end">
                <div className="mb-4 ">
                  <a
                    href="https://www.instagram.com/cricketgullyofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex"
                  >
                    <img src={instagram} alt="Instagram" className="w-[40px] h-[40px]" />
                  </a>
                </div>
                <div className="mb-4 ">
                  <a
                    href="https://www.facebook.com/cricketgully.official/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex"
                  >
                    <img src={facebook} alt="Facebook" className="w-[40px] h-[40px]" />
                  </a>
                </div>
                <div className="mb-4 ">
                  <a
                    href="https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex"
                  >
                    <img src={youtube} alt="Youtube" className="w-[40px] h-[40px]" />
                  </a>
                </div>
                <div className="mb-4 ">
                  <a
                    href="https://x.com/thecricketgully"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex"
                  >
                    <img src={twitter} alt="Twitter" className="w-[40px] h-[40px]" />
                  </a>
                </div>
              </ul>
            </div>
            <div className="gap-4 md:hidden flex flex-wrap justify-center mt-9">
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/about-us"} onClick={doScrollToTop}>
                  About Us
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/privacy-policy"} onClick={doScrollToTop}>
                  Privacy Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/terms-of-use"} onClick={doScrollToTop}>
                  Terms of Use
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/correction-policy"} onClick={doScrollToTop}>
                  Correction Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/fact-check-policy"} onClick={doScrollToTop}>
                  Fact Check Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/cookie-policy"} onClick={doScrollToTop}>
                  Cookie Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/dmca-policy"} onClick={doScrollToTop}>
                  DMCA Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/p/disclaimer-policy"} onClick={doScrollToTop}>
                  Disclaimer Policy
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/authors"} onClick={doScrollToTop}>
                  Authors
                </Link>
              </p>
              <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
                <Link to={"/contact-us"} onClick={doScrollToTop}>
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6 px-4 md:mt-7 flex justify-around relative z-10">
        <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
          © 2024 CricketGully. All rights reserved
        </p>
        <div className="gap-4 hidden md:flex">
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/about-us"} onClick={doScrollToTop}>
              About Us
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/privacy-policy"} onClick={doScrollToTop}>
              Privacy Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/terms-of-use"} onClick={doScrollToTop}>
              Terms of Use
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/correction-policy"} onClick={doScrollToTop}>
              Correction Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/fact-check-policy"} onClick={doScrollToTop}>
              Fact Check Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/cookie-policy"} onClick={doScrollToTop}>
              Cookie Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/dmca-policy"} onClick={doScrollToTop}>
              DMCA Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/p/disclaimer-policy"} onClick={doScrollToTop}>
              Disclaimer Policy
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/authors"} onClick={doScrollToTop}>
              Authors
            </Link>
          </p>
          <p className="text-center text-[#FFFFFF] opacity-50 text-[14px]">
            <Link to={"/contact-us"} onClick={doScrollToTop}>
              Contact Us
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
