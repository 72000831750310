import React, { useRef } from 'react';
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from 'react-router-dom';
import { MatchBox } from '../../../../common/MatchBoxes';
import { RecentMatchformatDates } from '../../../../../helpers/dates';

const TeamViewRecent = () => {
  const { id: fullId } = useParams();
  const id = fullId.split('-').pop();
  const containerRef = useRef(null);
  const {
    data,
    error: isError,
    isLoading,
    mutate,
  } = useAxiosSWR(`/v1/teams/${id}/recentMatches`);
  return (
    <div>
      <div ref={containerRef} className="md:mx-0 mx-[6px] bg-white md:rounded-2xl rounded-xl border p-4">
        {isError && (
          <h3 className="text-red-500 mb-4"></h3>
        )}
        {isLoading && (
          <p className="text-gray-600 text-center"></p>
        )}
        <div data-testid="full_container">
          {data?.matches?.length === 0 && !isLoading && (
            <p className="text-gray-600 text-center">No recent matches available.</p>
          )}
          {data?.matches?.map((match, i) => (
            <div key={match.match_id} className="mb-6">
              <div className="mb-6 mt-[10px] md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center">
                {RecentMatchformatDates(match.date_start_utc)}
                <span className="custom-border-right ml-6"></span>
              </div>
              <div className='border rounded-xl'>
              <MatchBox match={match} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeamViewRecent;
