import React, { useState } from 'react'
import { ShimmerEffect } from '../../common/ShimmerEffect';
import ShortsInstance from './ShortsInstance';

const imgSrc = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/IMG_20241210_132202.webp'
const imgsrc2 = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/IMG_20241210_132153.webp'

const videoSrc = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/kd_video_1.mp4'
const videoSrc2 = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/kd_clean_bold.mp4'

const data = [
    {
        id: 1,
        media: [
            {
                type: 'image',
                src: imgSrc,
                title: 'Rohit Sharma',
                text: "Image 1",
            },
            {
                type: 'image',
                src: imgsrc2,
                title: 'Rohit Sharma',
                text: "Image 2",
            },
            {
                type: 'video',
                src: videoSrc,
                title: 'Vide 1',
            },
        ],
    },
    {
        id: 2,
        media: [
            {
                type: 'image',
                src: imgsrc2,
                text: "Sharma Ji is on the top of his league bywin",
            }
        ],
    },
    {
        id: 3,
        media: [
            {
                type: 'video',
                src: videoSrc2,
                title: 'Rohit Sharma',
                text: "Sharma Ji is on the top of his league bywin",
            },
            {
                type: 'image',
                src: imgsrc2,
                text: "Sharma Ji is on the top of his league bywin",
            }
        ],
    },
];

// const data = [
//     {
//         id: 1,
//         imgSrc: imgSrc,
//         videoSrc: videoSrc,
//         title: 'Rohit Sharma',
//         text: "Sharma Ji is on the top of his league bywin",
//     },
//     {
//         id: 2,
//         imgSrc: imgSrc,
//         videoSrc: videoSrc,
//         title: 'Rohit Sharma',
//         text: "An amazing performance by Sharma Ji leading the team to victory",
//     },
//     {
//         id: 3,
//         imgSrc: imgSrc,
//         videoSrc: videoSrc,
//         title: 'Rohit Sharma',
//         text: "Sharma Ji's incredible journey in cricket continues to inspire",
//     },
//     {
//         id: 4,
//         imgSrc: imgSrc,
//         videoSrc: videoSrc,
//         title: 'Rohit Sharma',
//         text: "Leading the league, Sharma Ji is unstoppable this season",
//     },
// ]

export default function Shorts() {

    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <>
            <div className='mt-2 md:m-0 mx-[6px] bg-white border md:rounded-2xl rounded-xl'>
                <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h1 className='text-lg md:text-2xl'>Popular Stories</h1>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <div className="flex flex-wrap items-stretch md:px-3 md:mb-4">
                    {/* {data.map((item, index) => (
                        <div
                            key={index}
                            className="block md:flex w-1/3 lg:w-1/4 p-[6px] md:p-[10px]"
                            onClick={() => { setActiveIndex(index); setIsOpen(true) }}
                        >
                            <div className="shorts-box relative rounded-xl md:rounded-[28px] overflow-hidden">
                                <ShimmerEffect
                                    src={item.imgSrc}
                                    alt='shorts-thumb'
                                    className='w-full h-[163px] md:h-full rounded-xl md:rounded-[28px] object-center'
                                />
                                <div className="absolute bottom-0 w-fit z-10 p-2 md:p-5">
                                    <p className="text-white text-[12px] md:text-2xl font-medium line-clamp-2">
                                        {item.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))} */}
                    {data.map((item, index) => {
                        const firstMedia = item.media[0];
                        return (
                            <div
                                key={item.id}
                                className="block md:flex w-1/3 lg:w-1/4 p-[6px] md:p-[10px]"
                                onClick={() => { setActiveIndex(index); setIsOpen(true); }}
                            >
                                <div className="shorts-box relative rounded-xl md:rounded-[28px] overflow-hidden cursor-pointer">
                                    {firstMedia.type === 'image' ? (
                                        <ShimmerEffect
                                            src={firstMedia.src}
                                            alt='shorts-thumb'
                                            className='w-full h-full rounded-xl md:rounded-[28px] object-center'
                                        />
                                    ) : (
                                        <video
                                            src={firstMedia.src}
                                            className="w-full h-full rounded-xl md:rounded-[28px] object-cover"
                                        />
                                    )}
                                    <div className="absolute bottom-0 w-fit z-10 p-2 md:p-5">
                                        <p className="text-white text-[12px] md:text-2xl font-medium line-clamp-2">
                                            {firstMedia.text || firstMedia.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ShortsInstance data={data} isOpen={isOpen} setIsOpen={setIsOpen} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        </>
    )
}


{/* {
    isOpen && (
        <>
            <div className='fixed inset-0 z-[10001] w-full h-screen'>
                <div className='relative shorts-container h-full'>
                    <button
                        className='absolute top-0 left-0 bg-white z-10 hidden md:block'
                        onClick={() => setIsOpen(false)}
                    >
                        Close
                    </button>
                    <button
                        className='absolute top-1/2 left-[31%] z-10 hidden md:block'
                        onClick={() => setActiveIndex((prev) => Math.max(prev - 1, 0))}
                    >
                        <img src={previous} alt="previous" width='65' />
                    </button>
                    <button
                        className='absolute top-1/2 right-[31%] z-10 hidden md:block'
                        onClick={() => setActiveIndex((prev) => Math.min(prev + 1, data.length - 1))}
                    >
                        <img src={next} alt="next" width='65' />
                    </button>
                    <div className="flex items-center justify-center w-full h-full overflow-hidden">
                        {data.map((item, index) => {
                            const offset = index - activeIndex;
                            const isActive = activeIndex === index;
                            const isPrevious = index === activeIndex - 1;
                            const isNext = index === activeIndex + 1;

                            let transformValue = 0;

                            if (isActive) {
                                transformValue = 0;
                            } else if (isPrevious || isNext) {
                                transformValue = offset * 450;
                            } else {
                                transformValue = offset > 0 || offset < 0 ? offset * 350 : offset * 200;
                            }

                            return (
                                <div
                                    key={item?.id}
                                    onClick={() => setActiveIndex(index)}
                                    className={`absolute transition-opacity duration-300 ${isActive
                                        ? 'opacity-100'
                                        : 'opacity-40'
                                        }`}
                                    style={{
                                        transform: `translateX(${transformValue}px)`
                                    }}
                                >
                                    <div className="relative md:rounded-[28px] overflow-hidden">
                                        <div className={`shorts-box relative ${isActive
                                            ? 'w-screen h-screen md:w-[488px] md:h-[800px] md:rounded-[28px]'
                                            : 'w-[191px] h-[335px] rounded-[19px]'
                                            }`}>
                                            <video
                                                ref={(el) => (videoRefs.current[index] = el)}
                                                className={`${window.innerWidth < 768 && isActive ? 'w-screen h-screen' : ''}`}
                                                width={isActive && window.innerWidth >= 768 ? 488 : undefined}
                                                height={isActive && window.innerWidth >= 768 ? 800 : undefined}
                                                poster={item.imgSrc}
                                                autoPlay={isActive}
                                            >
                                                <source src={item.videoSrc} />
                                            </video>
                                            {isActive ?
                                                <>
                                                    <div className='absolute top-5 md:top-7 left-4 md:left-6 h-1 w-11/12 md:w-10/12 rounded-[30px] bg-[#747474] overflow-hidden'>
                                                        <span
                                                            className='block bg-white h-full'
                                                            style={{
                                                                width: durations[activeIndex] > 0
                                                                    ? `${(currentTimes[activeIndex] / durations[activeIndex]) * 100}%`
                                                                    : '0%',
                                                                transition: 'width 0.2s ease'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='hidden md:flex items-center justify-center absolute top-2 right-2 z-10 h-10 w-10 rounded-full bg-[#00000033] backdrop-blur-lg'>
                                                        <img src={shareIcon} alt="share-icon" />
                                                    </div>
                                                    <div className='absolute top-9 md:top-14 right-3 flex items-center gap-2 z-10 bg-[#00000033] backdrop-blur-lg p-1 rounded-xl overflow-hidden'>
                                                        <button
                                                            onClick={handleMuteToggle}
                                                        >
                                                            {isMuted ? <img src={unMuteIcon} alt="unmute-icon" /> : <img src={muteIcon} alt='mute-icon' />}
                                                        </button>
                                                        <button
                                                            onClick={handlePauseToggle}
                                                        >
                                                            {isPaused ? <img src={playIcon} alt="play-icon" /> : <img src={pauseIcon} alt='pause-icon' />}
                                                        </button>
                                                        <button className='block md:hidden'>
                                                            <img src={shareIcon} alt="share-icon" />
                                                        </button>
                                                    </div>
                                                </>
                                                : ''
                                            }
                                        </div>
                                        <div className="shorts-cont absolute bottom-0 w-full z-10 p-5">
                                            {item.title ?
                                                <h2 className={`block font-semibold text-white ${isActive ? 'title-animation text-[32px] md:text-[50px] mb-6' : 'text-[19px]'}`}>{item.title}</h2>
                                                : ''
                                            }
                                            {item.title && item.text ?
                                                <span className={`shorts-text-line mt-5 ${isActive ? 'block' : 'hidden'}`} ></span>
                                                : ''
                                            }
                                            {item.text ?
                                                <p className={`text-white text-[30px] md:text-[40px] font-normal leading-2 ${isActive ? 'block' : 'hidden'}`}>
                                                    {item.text}
                                                </p>
                                                : ''
                                            }
                                            <div className={`shorts-read-more-btn ${isActive ? 'block' : 'hidden'}`}>
                                                <img src={readMoreArrow} alt="read-more-arrow" className='mx-auto mb-2' />
                                                <button className={`flex items-center gap-2 bg-white text-[#3A32D1] text-[14px] font-bold shadow-sm rounded-lg h-9 px-2 mx-auto`}>
                                                    <img src={item.imgSrc} alt="shorts-thumb" className='h-[22px] w-[22px] rounded-[4px]' />
                                                    Read More
                                                    <img src={downArrow} alt="down-arrow" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    )
} */}
