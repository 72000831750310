import React from 'react'
import { Link } from 'react-router-dom'

export default function CGLink({ to, children, doHardRedirect, ...others }) {
    return (
        <>
            {doHardRedirect ?
                <a href={to} {...others}>
                    {children}
                </a>
                :
                <Link to={to} {...others}>
                    {children}
                </Link>
            }
        </>
    )
}
