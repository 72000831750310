export default function LiveAnimatedRedDot({
    height = '8px',
    width = '8px',
    className
}) {
    return (
        <>
            <span
                className={`block bg-[#ff0000] rounded-full shadow-[0px_0px_1px_1px_#FFCCD0] animate-shadowPulse ${className}`}
                style={{ height: height, width: width}}
            ></span>
        </>
    )
}
