export const isLiveNewsTag = (isLive) => {
  return isLive === true || isLive === "true";
}
export function getMeParsedJSON(value, defaultValue = {}) {
  try {
    return JSON.parse(value) || defaultValue;
  } catch {
    return defaultValue;
  }
}

export function getNewsDetailsLinkPath(slug, id, type) {
  if (type === 'fantasy') {
    return `/fantasy-cricket-tips/${slug}-${id}`;
  }  else if (type === 'top_story') {
    return `/cricket-top-story/${slug}-${id}`
  }
  return `/cricket-news/${slug}-${id}`;
}

export function doScrollToTop(timeout) {
  if (timeout === undefined) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, timeout);
  }
}

export function getSeriesAbbr(str) {
  let ans = '';
  let ch = '';
  for (let i = 0; i < str.length; i++) {
    ch = str[i];
    if ( (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z') || (ch >= '0' && ch <= '9') || ch === '-') {
      ans = ans + ch;
    } else {
      ans = ans + '-'
    }
  }
  return ans;
}

export function scrollSmooth(id, yOffset = -150) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }

  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

window.scrollSmooth = scrollSmooth;

export const getSanitizedArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  }
  return [];
}

export const getSanitizedWinPer = (value) => {
  let ans = 0;
  if (typeof value === "number" && isNaN(value) === false) {
    // ans = Math.round(value * 10) / 10;
    ans = Math.round(value); // 92.16 -> 92, 92.56 -> 93
  }
  return ans;
}

export const resizedImageURL = (url, size)=>{
  if(!url) return url;

  let arr = url.split("static.cricketgully.com/");
  if(arr.length<2){
    return url; //don't resize.
  }else{
    return `https://static.cricketgully.com/fit-in/${size}/${arr[1]}`;
  }
}

export const srcsetImageURL = (url, mediumSize, largeSize)=>{
  if(!url) return url;
  
  const mediumWidth = "768w"; //min-width
  const largeWidth = "1024w"; //min-width
  let arr = url.split("static.cricketgully.com/");
  if(arr.length<2){
    return url; //don't resize.
  }
  const urls=[];
  urls.push(`https://static.cricketgully.com/fit-in/${mediumSize}/${arr[1]} ${mediumWidth}`);
  urls.push(`https://static.cricketgully.com/fit-in/${largeSize}/${arr[1]} ${largeWidth}`);
  return urls.join(", ");
}