import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxiosSWR } from "root/axiosInstance";
import VideosContainer from '../../../../videosV2';

const TeamViewVideos = () => {
  const { id: fullId } = useParams();
  const id = fullId.split('-').pop();
  const { data: videoData, error: isError, isLoading } = useAxiosSWR(`/v1/teams/${id}/videos`);
  const [open, setOpen] = useState(false);
  const [youTubeUrl, setYouTubeUrl] = useState('');

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="md:mx-0 mx-[6px] bg-white rounded-2xl border overflow-auto">
      <VideosContainer
        videosData={videoData || []}
        open={open}
        setOpen={setOpen}
        youTubeUrl={youTubeUrl}
        setYouTubeUrl={setYouTubeUrl}
        onClose={onClose}
        isLoading={isLoading}
        hasError={isError}
        title="Top India Team Videos"
      />
    </div>
  );
};

export default TeamViewVideos;
